<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off">
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          alt="Image"
          @click="selectImage"
        ></div>
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>

      <div class="my-card">
        <div class="row">
          <CustomSelectBoxWithImg
            :className="'col-md-12'"
            :id="'activityTypeToken'"
            :value="brand.activityTypeToken"
            :options="activityTypeTokenOptions"
            v-on:changeValue="brand.activityTypeToken = $event"
            :title="$t('activityTypes.select')"
            :imgName="'activity-types.svg'"
          />

          <CustomInput
            :className="'col-md-6'"
            :id="'fullCode'"
            :value="brand.fullCode"
            v-on:changeValue="brand.fullCode = $event"
            :title="$t('code')"
            :imgName="'code.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'brandDiscountPercentage'"
            :value="brand.brandDiscountPercentage"
            v-on:changeValue="brand.brandDiscountPercentage = $event"
            :title="$t('brands.discountPercentage')"
            :imgName="'maxDiscountPercentage.svg'"
          />

          <CustomInput
            :className="'col-md-6'"
            :id="'brandNameAr'"
            :value="brand.brandNameAr"
            v-on:changeValue="brand.brandNameAr = $event"
            :title="$t('brands.nameAr')"
            :imgName="'brand.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'brandNameEn'"
            :value="brand.brandNameEn"
            v-on:changeValue="brand.brandNameEn = $event"
            :title="$t('brands.nameEn')"
            :imgName="'brand.svg'"
          />

          <TextArea
            :className="'col-md-11'"
            :id="'mapLink'"
            :value="brand.mapLink"
            v-on:changeValue="brand.mapLink = $event"
            :title="$t('brands.mapLink')"
            :imgName="'location.svg'"
          />
          <CustomCheckbox
            :className="'col-md-1'"
            :value="brand.mapLinkStatus"
            v-on:changeValue="brand.mapLinkStatus = $event"
            :title="$t('activeStatus')"
          />

          <TextArea
            :className="'col-md-11'"
            :id="'faceBookLink'"
            :value="brand.faceBookLink"
            v-on:changeValue="brand.faceBookLink = $event"
            :title="$t('brands.faceBookLink')"
            :imgName="'facebook.svg'"
          />
          <CustomCheckbox
            :className="'col-md-1'"
            :value="brand.faceBookLinkStatus"
            v-on:changeValue="brand.faceBookLinkStatus = $event"
            :title="$t('activeStatus')"
          />

          <TextArea
            :className="'col-md-11'"
            :id="'twitterLink'"
            :value="brand.twitterLink"
            v-on:changeValue="brand.twitterLink = $event"
            :title="$t('brands.twitterLink')"
            :imgName="'twitter.svg'"
          />
          <CustomCheckbox
            :className="'col-md-1'"
            :value="brand.twitterLinkStatus"
            v-on:changeValue="brand.twitterLinkStatus = $event"
            :title="$t('activeStatus')"
          />
          <TextArea
            :className="'col-md-11'"
            :id="'instagramLink'"
            :value="brand.instagramLink"
            v-on:changeValue="brand.instagramLink = $event"
            :title="$t('brands.instagramLink')"
            :imgName="'instagram.svg'"
          />
          <CustomCheckbox
            :className="'col-md-1'"
            :value="brand.instagramLinkStatus"
            v-on:changeValue="brand.instagramLinkStatus = $event"
            :title="$t('activeStatus')"
          />
          <TextArea
            :className="'col-md-11'"
            :id="'webSiteLink'"
            :value="brand.webSiteLink"
            v-on:changeValue="brand.webSiteLink = $event"
            :title="$t('brands.webSiteLink')"
            :imgName="'link.svg'"
          />
          <CustomCheckbox
            :className="'col-md-1'"
            :value="brand.webSiteLinkStatus"
            v-on:changeValue="brand.webSiteLinkStatus = $event"
            :title="$t('activeStatus')"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'brandDescriptionAr'"
            :value="brand.brandDescriptionAr"
            v-on:changeValue="brand.brandDescriptionAr = $event"
            :title="$t('brands.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'brandDescriptionEn'"
            :value="brand.brandDescriptionEn"
            v-on:changeValue="brand.brandDescriptionEn = $event"
            :title="$t('brands.descriptionEn')"
            :imgName="'description.svg'"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'brandNotes'"
            :value="brand.brandNotes"
            v-on:changeValue="brand.brandNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateBrand"
        >
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'Brands' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { BASE_URL } from "@/utils/constants";
import ActivityType from "@/models/activityTypes/ActivityType";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBoxWithImg from "@/components/general/CustomSelectBoxWithImg.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    CustomSelectBoxWithImg,
    CustomCheckbox,
    TextArea,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "constantsListsData"]),
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      imageSrc: "",
      activityType: new ActivityType(),
      activityTypeTokenOptions: [],
    };
  },
  props: ["brand", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.brand.mediaFile = file[0];
      }
    },
    async addOrUpdateBrand() {
      this.$emit("addOrUpdateBrand");
    },

    async getDialogOfActivityTypes() {
      this.isLoading = true;
      try {
        this.activityTypeTokenOptions =
          await this.activityType.getDialogOfActivityTypes(
            this.language,
            this.userAuthorizeToken
          );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.imageSrc = this.brand.brandImagePath
      ? BASE_URL + this.brand.brandImagePath
      : this.brand.defaultImg;

    this.getDialogOfActivityTypes();
  },
};
</script>

<style lang="scss"></style>
